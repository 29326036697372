import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/hospitality.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Hospitality Businesses | Camelo"
        metaDescription="Scheduling & time tracking software for hospitality businesses. Eliminate the stress of admin work so you have more time to improve your services."
        header="Less admin work. More time for delivering the best service."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
